<template>
  <div class="labs-page">
    <v-card color="#fff" flat>
      <v-card-title class="pt-6 pb-9 px-4">
        <h3 v-html="warningHeader"></h3>
      </v-card-title>
    </v-card>

    <v-card color="#1F6070" flat>
      <v-card-title>
        <h1>Your results</h1>
      </v-card-title>
    </v-card>
    <div class="pt-6 px-4">
      <v-card
        v-for="item in categories"
        :key="item"
        color="#fff"
        flat
        class="mb-4 mx-4"
        style="border-radius: 16px"
        @click="goToLink(item)"
      >
        <v-card-title>
          <h2>{{ item }}</h2>
          <v-spacer></v-spacer>
          <img
            width="15px"
            height="30px"
            :src="require('../../assets/svg/right.svg')"
            class="pr-2"
          />
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>
<script>
import { api, auth } from '../../sharedPlugin';
export default {
  data: () => ({
    warningHeader: `
  Lab test results (except HIV), semen analysis, and monitoring ultrasound reports will appear below after being reviewed by your provider.<br>
  <i>You may see some variability in the ultrasound reports, as measurements can vary based on anatomy and provider.<br>
  Please message your care team if you have any questions about your results.</i>
`,
    categories: ['Blood test results']
  }),
  mounted() {
    this.getCategories();
  },
  computed: {
    ...auth.mapComputed(['user'])
    // categories() {
    //   return ['Blood test results']; //, 'Embryology results', 'Semen analysis'
    // }
  },
  methods: {
    goToLink(item) {
      if (item === 'Blood test results') {
        this.$router.push({ path: '/labs/blood-test' });
      }

      if (this.title === 'Embryology results') {
        this.$router.push({ path: '/labs/embryology' });
      }

      if (this.title === 'Semen analysis') {
        this.$router.push({ path: '/labs/semen-analysis' });
      }

      if (item === 'Ultrasound Results') {
        this.$router.push({ path: '/labs/ultrasound-result' });
      }
    },
    getCategories() {
      // Get the ultrasound value from the API
      api.Labs.doUSReportsExist(this.user.patientEhrId, this.user.clinicId)
        .then(({ data }) => {
          console.log('Does Follicular UltraSound Reports Exist?: ');
          console.log(data);
          let ultrasoundExist = data.some((o) => o.Ultra_Sounds_Exist === true);
          if (ultrasoundExist) {
            this.categories.push('Ultrasound Results');
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);

          this.isLoading = false;
          // Handle the error by throwing an error message
        });
    }
  }
};
</script>
<style lang="scss">
.labs-page {
  .v-card__title {
    h1 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      white-space: pre-wrap;
      word-break: break-word;
    }
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
</style>
